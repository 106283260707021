<template>
  <Breadcrumb />
  <SetLoader v-if="!systemMessageTemplateData" classes="mt-5" />
  <div class="row" v-else>
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="form-messagetemplate-edit pe-2 ps-2" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="row row-cols-1">
              <div class="col col-md-4">
                <label for="Name" class="form-label required"
                  >{{
                    $t(
                      "SystemMessageTemplates.Name",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="Name"
                  v-model="this.systemMessageTemplateData.name"
                  :autocomplete="this.$isAutoComplete"
                  :spellcheck="this.$isTextSpellCheck"
                  required
                />
              </div>
              <div class="col col-md-4">
                <label for="templateKey" class="form-label required">{{
                  $t(
                    "SystemMessageTemplates.TemplateKey",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :selectedData="selectedTemplateKey"
                  :allowEmpty="false"
                  :data="templateKeyData"
                  @onChange="onChangeForTemplateKey"
                />
              </div>
              <div class="col col-md-4">
                <label for="templateLanguage" class="form-label required">{{
                  $t(
                    "SystemMessageTemplates.TemplateLanguage",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <FormSelect
                  type="SelectWithLocalData"
                  :selectedData="selectedTemplateLanguage"
                  :allowEmpty="false"
                  :data="templateLanguageData"
                  @onChange="onChangeForTemplateLanguage"
                />
              </div>
            </div>
            <div class="row row-cols-1 mt-3">
              <div class="col col-md-12">
                <label for="Description" class="form-label">{{
                  $t(
                    "SystemMessageTemplates.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <textarea
                  name="Description"
                  class="form-control"
                  id="Description"
                  rows="2"
                  v-model="this.systemMessageTemplateData.description"
                  :spellcheck="this.$isTextSpellCheck"
                ></textarea>
              </div>
            </div>
            <div class="cols-12 mt-3">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "MessageTemplates.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="this.systemMessageTemplateData.isActive"
                />
              </div>
            </div>
            <div class="cols-12 mt-2">
              <label for="TemplateDesign" class="form-label">{{
                $t(
                  "SystemMessageTemplates.TemplateDesign",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <CKEditor
                ref="ckEditor"
                id="TemplateDesign"
                type="SystemMessageTemplate"
                :value="systemMessageTemplateData.templateDesign"
              />
            </div>
            <ActionButtons
              classes="mt-3"
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CKEditor from "@/components/ckeditor/Editor.vue";
import $ from "jquery";
export default {
  name: "SystemMessageTemplateEdit",
  components: { CKEditor },
  data() {
    return {
      templateKeyData: [
        {
          key: "BulkImports",
          value: this.$t(
            "SystemMessageTemplates.BulkImports",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "ResetPassword",
          value: this.$t(
            "SystemMessageTemplates.ResetPassword",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "DataExport",
          value: this.$t(
            "SystemMessageTemplates.DataExport",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "SendTestMail",
          value: this.$t(
            "SystemMessageTemplates.SendTestMail",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      templateLanguageData: [
        {
          key: "tr",
          value: this.$t(
            "Language.tr",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "en",
          value: this.$t(
            "Language.en",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedTemplateKey: [],
      selectedTemplateLanguage: [],
      systemMessageTemplateData: null,
      templateKey: "",
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
    };
  },
  methods: {
    onChangeForTemplateKey: function (selected) {
      this.systemMessageTemplateData.templateKey = selected.key;
    },
    onChangeForTemplateLanguage: function (selected) {
      this.systemMessageTemplateData.templateLanguage = selected.key;
    },
    onSubmit(buttonName) {
      var form = $(".form-messagetemplate-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.systemMessageTemplateData.templateDesign =
        this.$refs.ckEditor.editor.getData();

      this.errors = [];
      this.$prodGatewayAxios
        .post(
          "/Lcdp-SystemMessageTemplateUpdate",
          this.systemMessageTemplateData
        )
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/SystemMessageTemplates/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
    getSystemMessageTemplateData() {
      var id = this.$route.params.systemMessageTemplateId;
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-SystemMessageTemplateGet?id={0}", id))
        .then((response) => {
          this.systemMessageTemplateData = response.data;
          var selectedTemplateKey = this.templateKeyData.find(
            (x) => x.key == response.data.templateKey
          );
          this.selectedTemplateKey.push(selectedTemplateKey);

          var selectedLanguae = this.templateLanguageData.find(
            (x) => x.key == response.data.templateLanguage
          );
          this.selectedTemplateLanguage.push(selectedLanguae);
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getSystemMessageTemplateData();
  },
};
</script>
