<template>
  <textarea
    :id="id"
    :name="id"
    class="hidden form-control"
    v-model="editorValue"
  ></textarea>
</template>
<script>
import $ from "jquery";
export default {
  name: "CkEditor",
  props: ["id", "type", "value"],
  data() {
    return {
      editorValue: this.value,
      editor: null,
    };
  },
  mounted() {
    var documentCkEditor = document.getElementById(this.id),
      ckeditors = window.CKEDITOR;

    if (documentCkEditor !== null) {
      documentCkEditor.classList.add("hidden");
    }

    ckeditors.replace(this.id, {
      htmlEncodeOutput: false,
      extraPlugins: "tableresize",
      contentsCss: [
        "/extras/plugins/ckeditor/contents.css",
        "/extras/plugins/ckeditor/customfonts/fonts.css",
      ],
    });

    var ckEditorInstance = ckeditors.instances[this.id];
    this.editor = ckEditorInstance;

    if (!String.isNullOrWhiteSpace(ckEditorInstance)) {
      ckEditorInstance.config.language = this.$store.state.activeLang;

      var self = this;
      ckEditorInstance.on("change", () => {
        self.editorValue = ckEditorInstance.getData();
      });

      ckEditorInstance.setData(this.editorValue);

      ckEditorInstance.on("contentDom", function () {
        var editable = ckEditorInstance.editable();

        if (self.type == "MessageTemplate") {
          editable.attachListener(
            ckEditorInstance.document,
            "keyup",
            function () {
              if (self.$parent.messageTemplateData.templateType == "2") {
                var txt = ckEditorInstance.getData();
                $("#cke_1_path_label")
                  .text($(txt).text().replace(/\n/g, "").length)
                  .css("color", "#484848")
                  .css("font-weight", "bold")
                  .css("font-size", "13px")
                  .css("float", "right")
                  .css("margin", "0 15px 0")
                  .show();
              }
            }
          );
        } else if (self.type == "SystemMessageTemplate") {
          editable.attachListener(
            ckEditorInstance.document,
            "keyup",
            function () {
              if (
                self.$parent.systemMessageTemplateData.templateKey ==
                "BulkImports"
              ) {
                var txt = ckEditorInstance.getData();
                $("#cke_1_path_label")
                  .text($(txt).text().replace(/\n/g, "").length)
                  .css("color", "#484848")
                  .css("font-weight", "bold")
                  .css("font-size", "13px")
                  .css("float", "right")
                  .css("margin", "0 15px 0")
                  .show();
              }
            }
          );
        }
      });
    }
  },
};
</script>
